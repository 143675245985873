export const PAYMENT_TYPES_DICT = [
  { key: 'PayPal', value: 'Paypal', labelKey: 'SHEIN_KEY_PWA_19466' },
  {
    key: 'bankaccount',
    value: 'Bankaccount',
    langKey: 'SHEIN_KEY_PWA_18872',
    labelKey: 'SHEIN_KEY_PWA_19467'
  },
  { key: 'Paytm', value: 'Paytm' },
  { key: 'Pix', value: 'Pix', langKey: 'SHEIN_KEY_PWA_26120' }
]

export const INDIA_SITES = [
  'in',
  'pwin',
  'min',
  'iosshin',
  'andshin',
  'rwin',
  'rwmin',
  'iosrwin',
  'andrwin'
]

// 是否是印度区域
export function isIndia(code) {
  const { SiteUID } = window?.gbCommonInfo || {}
  if (code == 'IN' || INDIA_SITES.includes(SiteUID)) return { area: 'india', code: 'IN' }
  return false
}

// 东南亚站点
export const SOUTHEAST_SITES = [
  {
    siteuid: ['th', 'mth', 'iosshth', 'andshth'],
    area: 'TH'
  },
  {
    siteuid: ['vn', 'mvn', 'iosshvn', 'andshvn'],
    area: 'VN'
  },
  {
    siteuid: ['my', 'mmy', 'iosshmy', 'andshmy'],
    area: 'MY'
  },
  {
    siteuid: ['jp', 'mjp', 'iosshjp', 'andshjp'],
    area: 'JP'
  },
  {
    siteuid: ['tw', 'mtw', 'iosshtw', 'andshtw'],
    area: 'TW'
  },
  {
    siteuid: ['ph', 'mph', 'iosshph', 'andshph'],
    area: 'PH'
  },
  {
    siteuid: ['asia', 'masia', 'iosshasia', 'andshasia'],
    area: 'TH'
  }
]

export const SOUTHEAST_AREAS_SUPPORTS = ['TH', 'VN', 'MY', 'JP', 'TW', 'PH']

// 是否是东南亚区域
export function isSouthEast(code) {
  const { SiteUID } = window?.gbCommonInfo || {}
  if (SOUTHEAST_AREAS_SUPPORTS.includes(code)) {
    return { area: 'southeast', code }
  }
  const index = SOUTHEAST_SITES.findIndex(v => v.siteuid.includes(SiteUID))
  if (index >= 0) {
    return { area: 'southeast', code: SOUTHEAST_SITES[index].area }
  }
  return false
}

// 巴西站点
export const BRAZIL_SITES = ['br', 'mbr', 'iosshbr', 'andshbr']
export const MEXICO_SITES = ['mx', 'pwmx', 'iosshmx', 'andshmx']

// 是否是巴西区域
export function isBrazil(code) {
  const { SiteUID } = window?.gbCommonInfo || {}
  if (code == 'BR' || BRAZIL_SITES.includes(SiteUID) || code == 'Brazil')
    return { area: 'brazil', code: 'BR' }
  return false
}

// 墨西哥区域
export function isMx(code) {
  const { SiteUID } = window?.gbCommonInfo || {}
  if (code == 'MX' || MEXICO_SITES.includes(SiteUID) || code == 'Mexico')
    return { area: 'Mexico', code: 'MX' }
  return false
}
