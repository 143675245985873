import { extractQueryString, parseQueryString } from '@shein/common-function'

function formatParams (options) {
  const { refund_token } = parseQueryString(location.search)
  const queryString = extractQueryString(options.url)
  if (queryString) {
    options.url = options.url.replace(/\?.*/g, '')
    options.params = { ...(options.params || {}), ...(parseQueryString(queryString)) }
  }
  if (refund_token) {
    if (options.method == 'GET') {
      options.params = { ...(options.params || {}), userToken: refund_token }
      return
    }
    if(options.method == 'POST') {
      if (!options.data) options.data = {}
      options.data.userToken = refund_token
      return
    }
  }
}

function request (options) {
  formatParams(options)
  return new Promise((resolve, reject) => {
    // eslint-disable-next-line no-undef
    schttp({ ...options }).then(resolve).catch(reject)
  })
}
export default request
